import Layout from "../components/layout"
import Hero from "../components/hero"
import React from "react"
import Reviews from "../components/reviews"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import TwentyTwenty from "react-twentytwenty"
import OptInForm from "../components/optInForm"
import GoogleReviewCarousel from "../components/google-review-carousel"
import PortfolioCarousel from "../components/portfolioCarousel"
import * as ServicePageStyles from "../styles/servicepage.module.scss"
import * as SliderStyles from "../styles/slider.module.scss"

const CabinetPaintingPage = () => {
  const data = useStaticQuery(graphql`
    query CabinetQuery {
      heroImage: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1433-north-cleveland-ave-chicago-15"
        }
      ) {
        secure_url
      }
      imageRevealLeft: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-3329-west-polk-street-chicago-1"
        }
      ) {
        secure_url
      }
      imageRevealRight: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-3329-west-polk-street-chicago-2"
        }
      ) {
        secure_url
      }
      portfolioImg1: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1433-north-cleveland-ave-chicago-13"
        }
      ) {
        secure_url
      }
      portfolioImg2: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-3636-west-dickens-avenue-chicago-23"
        }
      ) {
        secure_url
      }
      portfolioImg3: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/portfolio/20210413_121130" }
      ) {
        secure_url
      }
      portfolioImg4: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-3329-west-polk-street-chicago-10"
        }
      ) {
        secure_url
      }
      bmIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/icons/bm" }) {
        secure_url
      }
      swIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/sherwin-williams" }
      ) {
        secure_url
      }
      googleIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/5-Google-Review-Badge" }
      ) {
        secure_url
      }
      angiIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/toprated-solid-border" }
      ) {
        secure_url
      }
      mdx(
        frontmatter: {
          title: { eq: "How Much Does It Cost To Paint Cabinets?" }
        }
      ) {
        excerpt(pruneLength: 250)
        frontmatter {
          description
          title
          date
          path
        }
      }
      linkedArticleImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/blog/how-much-does-it-cost-to-paint-cabinets" }
      ) {
        secure_url
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Cabinet Refinishing Chicago | Pivot Painters Chicago"
        description="Cabinet Refinishing Chicago is one of the best ways to refresh the look of your home. Save money with cabinet refinishing and schedule a free estimate!"
      />
      <Hero
        name="Cabinet Refinishing Chicago"
        subtitle="Transform Your Kitchen"
        src={data.heroImage.secure_url}
        quoteLink={"/cabinet-virtual-quote"}
      />
      <div className={ServicePageStyles.servicePageContainer}>
        <section className={ServicePageStyles.trustBadges}>
          <img
            src={data.bmIcon.secure_url}
            alt="Pivot Painters Chicago - Benjamin Moore Icon"
          />
          <img
            src={data.swIcon.secure_url}
            alt="Pivot Painters Chicago - Sherwin Williams Icon"
          />
          <img
            src={data.angiIcon.secure_url}
            alt="Pivot Painters Chicago - Angi Leads Icon"
          />
          <img
            src={data.googleIcon.secure_url}
            alt="Pivot Painters Chicago - Google Icon"
          />
        </section>

        <div className={ServicePageStyles.flexContainer}>
          <PortfolioCarousel
            images={[
              data.portfolioImg1.secure_url,
              data.portfolioImg2.secure_url,
              data.portfolioImg3.secure_url,
              data.portfolioImg4.secure_url,
            ]}
          />
          <div className={ServicePageStyles.textContent}>
            <h2 className={ServicePageStyles.textContentHeader}>
              Cabinet Refinishing <span className="heavyLato">Done Right.</span>
            </h2>
            The best and <strong>most affordable</strong> way to update a
            kitchen is to refinish your cabinets. Cabinet refinishing in Chicago
            is a detailed and technical process that can revive the look of your
            cabinets and provide a factory-like finish.
            <br></br>
            <br></br>The whole process generally takes 4-5 days and is most
            often times <strong>1/5 the price</strong> of replacing your kitchen
            cabinets
            <br></br>
            <br></br>
            At Pivot Painters Chicago, our goal is to transform your home and
            provide you with the{" "}
            <strong>best cabinet refinishing Chicago has to offer.</strong> Let
            our professional painters help refresh your cabinets today!
            <Link className={ServicePageStyles.ctaButton} to="/request-a-quote">
              Schedule A Free Estimate
            </Link>
          </div>
        </div>
        <div className={ServicePageStyles.reviewsContainer}>
          <h2 className={ServicePageStyles.textContentHeader}>
            What Past Customers Are Saying
          </h2>
          <GoogleReviewCarousel />
        </div>

        <div className={ServicePageStyles.textContent}>
          <h2 className={ServicePageStyles.textContentHeader}>
            Cost of Cabinet Refinishing
          </h2>
          <div
            className={ServicePageStyles.flexContainer}
            style={{ flexDirection: "column" }}
          >
            <div className={ServicePageStyles.textContent}>
              Read our latest blog post on cabinet refinishing to learn how much
              it will cost to paint your cabinets professionally or{" "}
              <Link
                to="/cabinet-virtual-quote/"
                className={ServicePageStyles.inlineLink}
              >
                Click Here
              </Link>{" "}
              to get a free virtual cabinet painting quote today!
            </div>
            <div className={ServicePageStyles.blogCardContainer}>
              <div className={ServicePageStyles.blogCardMeta}>
                <div
                  className={ServicePageStyles.blogCardPhoto}
                  style={{
                    backgroundImage: `url(${data.linkedArticleImage.secure_url})`,
                  }}
                ></div>
                <ul className={ServicePageStyles.blogCardDetails}>
                  <li className={ServicePageStyles.blogCardDate}>
                    {data.mdx.frontmatter.date}
                  </li>
                </ul>
              </div>
              <div className={ServicePageStyles.blogCardDescription}>
                <p className={ServicePageStyles.blogCardTitle}>
                  {data.mdx.frontmatter.title}
                </p>
                <p className={ServicePageStyles.blogCardExcerpt}>
                  {data.mdx.excerpt}
                </p>
                <p className={ServicePageStyles.blogCardReadMoreButton}>
                  <Link
                    to={data.mdx.frontmatter.path}
                    className={ServicePageStyles.ctaButton}
                  >
                    Read More
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={ServicePageStyles.textContent}>
          <h2 className={ServicePageStyles.textContentHeader}>
            Why Hire Pivot Painters?
          </h2>
          Refinishing cabinets is <strong>highly technical</strong> and there is
          a right way and a wrong way to paint cabinets. Doing it incorrectly
          can invite deterioration, peeling, and loss of color in just a few
          years. <br></br>
          <br></br>With Pivot Painters Chicago, those worries are not an issue
          as our professional crews of painters use{" "}
          <strong>industry-leading techniques</strong> and materials to prevent
          them from happening.
          <br></br>
          <br></br>Our Chicago cabinet refinishing crews are committed to
          providing a <strong>beautiful, detailed, and durable result</strong>{" "}
          that will last for years. Let Pivot Painters, the top cabinet
          refinishing Chicago company transform your kitchen cabinets!
          <ul>
            <li>
              <i className="fas fa-brush"></i>
              <p>Our Cabinet Refinishers Are Trusted and Reliable</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Only Use the Highest Quality Materials and Equipment</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Quality Craftsmanship and Attention To Detail</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Years of Professional Experience</p>
            </li>
          </ul>
          {/*<div className="cabinetPricingContainer">
            <h3>How Much Does Cabinet Refinishing Cost?</h3>
            <PricingShortForm textContent="Get A Copy of Our Pricing Guide To See How Much Repainting Your Cabinets Will Cost" />
          </div>*/}
          <TwentyTwenty
            left={
              <img
                src={data.imageRevealLeft.secure_url}
                className={SliderStyles.sliderImage}
              />
            }
            right={
              <img
                src={data.imageRevealRight.secure_url}
                className={SliderStyles.sliderImage}
              />
            }
            slider={
              <div className={SliderStyles.slider}>
                <div className={SliderStyles.sliderIcon}>
                  <i className="fas fa-arrows-alt-h"></i>
                </div>
              </div>
            }
          />
          <div>
            <h2 className={ServicePageStyles.textContentHeader}>
              Advantages of Refinishing Your Cabinets
            </h2>
            <ul>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Refreshes Your Home</strong> Repainting or Refinishing
                  your cabinets can modernize an outdated kitchen, bathroom
                  vanity, shelves, or any other cabinetry you may have within
                  your house.
                </p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Cost Effective</strong> Updating the look of your home
                  doesn't need to cost a fortune. Refinishing or Painting your
                  existing cabinets is much cheaper than replacing them.
                </p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Convenience</strong> Pivot Painters Chicago uses
                  industry-leading techniques which means you get brand
                  new-looking cabinets without the long time and inconvenience
                  of replacing or refacing your cabinets.
                </p>
              </li>
            </ul>
            <p>
              Want to know more about our cabinet refinishing Chicago services?{" "}
              <a href="tel:3128549180‬">
                <strong>Call (312) 854-9180‬ today!</strong>
              </a>
            </p>
          </div>
          {/*<div className="cabinet-paintingServicesList">
            <h3>Pivot Painters Cabinet Refinishing and Painting Services</h3>
            <ul>
              <li>
                <i className="fas fa-brush"></i>
                <p>Kitchen Cabinets</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Bathroom Cabinets</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Built-In Shelving</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Cabinet Drawers</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Cabinet Doors</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Vanities</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Kitchen Islands</p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>Closet Storage</p>
              </li>
            </ul>
          </div>*/}
          <OptInForm isCabinetVirtualForm={true}></OptInForm>
        </div>

        <Reviews></Reviews>
      </div>
    </Layout>
  )
}

export default CabinetPaintingPage
